.cs-footer {
    display: grid;
    place-items: center;
    text-align: center;
    height: $footer-height;
    background-color: #fcfbfb;
    margin-top: 20px;
    p {
        margin: 0;
        a {
            text-decoration: none;
        }
    }
    .link {
        color: $hope;
        &:hover {
            color: darken($hope, 5);
            cursor: pointer;
        }
    }
}