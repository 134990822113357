.faq {
    &__title {
        display: grid;
        place-items: center;
        padding-top: 50px;
        padding-bottom: 20px;
        h3 {
            font-size: 2.5rem;
        }
    }
    .link {
        color: $hope;
        &:hover {
            color: darken($hope, 5);
            cursor: pointer;
        }
    }
}