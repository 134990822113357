.signin {
    &__forgot-text {
        color: $hope;
        font-size: 14px;
        font-weight: bold;
        &:hover {
            cursor: pointer;
            color: lighten($hope, 10)
        }
    }
    .loader-img {
        width: 20px;
        height: 20px;
    }
}