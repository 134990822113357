@import "./styles/index.scss";
@import "~bootstrap/scss/bootstrap";
.center-items {
    display: grid;
    place-items: center;
}

.hero-image {
    padding: 0;
    max-height: 200px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    &__title {
        display: grid;
        place-items: center;
        padding-top: 50px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        width: 100%;
        top: 0;
        text-align: left;
        h3 {
            font-size: 2em;
            font-weight: bold;
            color: #fff;
        }
    }
}

.activeLink {
    color: $hope;
    font-weight: 500;
    transition: all 0.4s;
    font-weight: bold;
}

.form-control.is-valid {
    border-color: green !important;
}

.valid-feedback {
    color: green;
}

.alert-success{
  background-color: $success;
  color:#fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.alert-danger{
  background-color: $error;
  color:#fff;
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
    font-weight: bold;
}