.cs-header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    height: $header-height;
    // color: #ffffff !important;
    a {
        display: grid;
        place-items: center start;
    }
    &__linkContent {
        display: grid;
        place-items: center start;
        // color: #ffffff !important;
        &.clientIcon {
            width: 105px;
            height: 48px;
            // color: #ffffff !important;
        }
    }
    .headerIcon {
        // color: #ffffff !important;
    }
    .clientSiteName{
        min-height: 48px;
        border-left: 1px solid $grey20;
        margin-left: 16px;
    }
    .siteName {
        color: #000000 !important;
        margin: 0;
        font-size: 0.9rem;
        line-height: 1.3;
        font-weight: bold;
    }
    .nav-link {
        color: #000000 !important;
    }
    .link {
        &:hover {
            color: $hope;
            cursor: pointer;
        }
    }
    .navbar {
        height: 70px
    }
}