.landing-page {
    background: url(/assets/absa-rtw-home-banner.png) center center no-repeat;
    background-size: cover;
    height: 100%;
    &__container {
        display: grid;
        place-items: center
    }
    &__platform-name {
        display: grid;
        place-items: center;
        text-align: center;
        p {
            font-size: 2.5rem;
            color: #ffffff;
            font-weight: bold;
            margin: 0;
        }
    }
    &__prompt {
        display: grid;
        place-items: center;
        text-align: center;
        p {
            font-size: 2.5rem;
            color: #ffffff;
            margin: 0;
        }
    }
    &__options {
        color: #ffffff;
        &__selection {
            &__icon {
                display: grid;
                place-items: center;
                img {
                    max-width: 60px;
                    height: auto;
                }
            }
            &__text {
                display: grid;
                place-items: center;
                text-align: center;
                font-weight: bold;
                margin-top: 20px;
            }
        }
    }
    &__mobile-text {
        display: grid;
        place-items: center;
        text-align: center;
        p {
            font-size: 2em;
            color: #ffffff;
            margin: 0;
            font-weight: 500;
        }
    }
    &__mobile-icons {
        .icon {
            display: grid;
            place-items: center;
            img {
                max-width: 150px;
                height: auto;
            }
             :hover {
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .landing-page {
        background: url(/assets/absa-rtw-home-banner.png) center center no-repeat;
        background-size: cover;
        background-position: right;
    }
}