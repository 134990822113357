body {
    margin: 0;
    font-family: 'SourceSansPro', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
}

* {
     font-family: 'SourceSansPro', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 720px) {
    html {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 960px) {
    html {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 1240px) {
    html {
        font-size: 16px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.placeholder-lg {
  min-height: 2.6em !important;
  margin-bottom: 12px;
  border-radius: 7px;
}