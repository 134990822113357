.resources {
    &__profile_info {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .1)!important;
        padding: 20px;
        &__image {
            display: grid;
            place-items: center;
            color: $hope;
            font-size: 50px;
        }
        &__user_info {
            display: grid;
            grid-template-rows: 50% 50%;
            .username {
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .email {
                overflow: hidden;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    &__inner-container {
        border-bottom: 1px solid rgb(236, 236, 236);
        padding-bottom: 20px;
    }
    &__module-progress {
        // border-bottom: 1px solid rgb(236, 236, 236);
        ul {
            margin-bottom: 0;
            list-style: none;
            padding: 0 0 15px 0;
            li {
                margin-top: 15px;
            }
        }
        &__number {
            display: grid;
            place-items: center;
            color: $uplift;
            font-size: 25px;
        }
        &__text {
            display: grid;
            place-items: center start;
        }
    }
    &__module-button {
        display: grid;
        place-items: center;
    }
    &__modules {
        background-color: #ffffff;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        padding: 0 15px;
        &:hover {
            cursor: pointer;
            background-color: #f8f8f8;
        }
        &__header {
            border-bottom: 1px solid #ececec;
        }
        &__header-text {
            padding-top: 20px;
            padding-bottom: 20px;
            // color: $hope;
            font-size: 25px;
            font-weight: 500;
        }
        &__time {
            display: grid;
            place-items: center end;
            font-weight: 600;
        }
        &__details {
            display: grid;
            ul {
                margin-bottom: 25px;
                li {
                    padding-top: 20px;
                }
            }
        }
        &__statistics {
            display: grid;
            grid-template-rows: 70% 30%;
            .module-progress {
                display: grid;
                place-self: end;
                place-items: center end;
                padding-top: 20px;
                padding-bottom: 20px;
                color: $hope;
                font-weight: 600;
                font-size: 22px;
            }
            .module-percent {
                display: grid;
                place-items: center end;
                p {
                    color: rgb(197, 197, 197);
                    font-size: 40px;
                }
            }
        }
    }
    p {
        margin: 0;
    }
}