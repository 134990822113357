.module-view {
    &__module_info {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .1)!important;
        padding: 20px;
        &__header {
            border-bottom: 1px solid rgb(236, 236, 236);
        }
        &__header-text {
            font-size: 18px;
            font-weight: 500;
            p {
                margin-bottom: 0;
            }
        }
        &__icon {
            display: grid;
            place-items: center;
            font-weight: 600;
            overflow: hidden;
        }
        &__body-list-item {
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border: 1px solid rgb(236, 236, 236);
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                cursor: pointer;
                p {
                    color: $hope;
                }
            }
            p {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
            }
        }
        &__body-list-container {
            padding: 0;
            list-style: none;
            .disabled {
                cursor: not-allowed;
                background-color: rgb(240, 239, 239);
                color: rgb(174, 173, 173);
                .row {
                    pointer-events: none;
                }
                &:hover {
                    p {
                        color: rgb(174, 173, 173);
                    }
                }
            }
        }
    }
    &__body {
        &__assessment {
            padding: 0 !important;
            background-color: #ffffff;
            border: 1px solid #ececec;
            margin-bottom: 20px;
            padding: 0;
            &__header {
                border-bottom: 1px solid #ececec;
            }
            &__header-text {
                padding-top: 20px;
                padding-bottom: 20px;
                // color: $hope;
                font-size: 25px;
                font-weight: 500;
            }
            &__time {
                display: grid;
                place-items: center end;
                font-weight: 600;
            }
            &__details {
                display: grid;
                ul {
                    margin-bottom: 25px;
                    li {
                        padding-top: 20px;
                    }
                }
            }
            .progress {
                height: 40px;
                border-radius: 0;
                .progress-bar {
                    height: 40px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }
    .header-text-progress {
        font-size: 22px;
        font-weight: 500;
        padding-inline-start: 15px;
        color: $hope;
        p {
            margin-bottom: 0;
        }
        .instructions {
            font-size: 16px;
        }
        h1 {
            // color: rgb(73, 73, 73);
            font-size: 28px;
            font-weight: bold;
        }
        &__offline {
            display: grid;
            place-items: center end;
            padding-inline-end: 25px;
            .form-check {
                .form-check-label {
                    font-size: 16px !important;
                }
            }
        }
    }
    &--selected {
        color: $hope;
    }
}