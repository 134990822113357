.form-control{
    &:focus {
        border-color: $grey50 !important;
    }
    font-weight: 600;
}
.card {
    box-shadow: 0 1px 4px $grey20 !important;
}
.ab-icon-button{
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: medium;
    font-family: "SourceSansPro", sans-serif !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
.accordion-item {
    border-left: none !important;
    border-right: none !important;
}
.accordion-button {
    font-weight: bold !important;
    font-size: 16px !important;
}