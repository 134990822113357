.assessment {
    &__header-text-progress {
        font-size: 32px;
        color: $hope;
        font-weight: bold;
        display: grid;
        grid-template-columns: 60% 40%;
    }
    &__question {
        font-size: 24px;
        color: $uplift;
        font-weight: 400;
    }
    &__answer {
        font-size: 18px;
    }
    &__instructions {
        color: #7d7d7d;
        .link {
            color: $hope;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__question-number {
        color: $uplift;
        font-size: 24px;
        font-weight: 500;
    }
    &__score {
        color: $uplift;
        font-size: 44px;
        font-weight: 500;
    }
    &__quiz-result {
        &__rate {
            margin-top: 20px;
            h3 {
                color: $uplift;
                font-weight: 600;
            }
        }
    }
}