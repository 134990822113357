.about-us {
    margin-bottom: 30px;
    &__head-txt {
        display: grid;
        place-items: center;
        text-align: center;
        p {
            font-size: 1.3rem;
            line-height: 35px;
        }
    }
    &__what-we-do {
        padding-top: 20px;
        &__what-we-do-entry {
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, .1)!important;
            padding-top: 10px;
            padding-bottom: 10px;
            ul {
                margin-bottom: 0;
                height: 200px;
                display: grid;
                padding-top: 20px;
                overflow-y: scroll;
                align-content: start;
                li {
                    font-size: 1.2rem;
                }
            }
        }
    }
    h1,h2,h3,h4{
        font-weight: bolder;
    }
}