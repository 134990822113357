// New Branding
$black: #131010; // Enrich
$white: #FFFFFF; // Serene
$text: #0f0f0f;
$brand: #DC0037; // Passion
$power: #B50232; // Power
$hope: #95052A; // Hope
$inspire: #77021E; // Inspire
$energy: #FF780F; // Energy
$uplift: #F93F24; // Uplift
$grey: #F8F6F6; // Grey 04
$grey10: #ECE9E9; // Grey 08
$grey20: #C7C5C5; // Grey 24
$grey30: #A19F9F; // Grey 40
$grey40: #686666; // Grey 60
$grey50: #4C4949; // Grey 72
$error: #C5163C; // Error-accent
$error-dark: #910826; // Error-text
$error-surface: #FAD9E0; // Error-surface
$success: #1CA643; // Positive-accent
$success-text: #168335; // Positive-text
$success-surface: #F3FCE9; // Positive-surface
$info: #2B82DA; // Info-accent
$info-dark: #1F4D7A; // Info-text
$info-surface: #EDF5FD; // Info-surface
$warning: #E35716; // Warning-accent
$warning-dark: #87310F; // Warning-text
$warning-surface: #FDECE4; // Warning-surface
$modal-overlay-color: rgba(0, 0, 0, 0.35); // Modal Overlay Color

// REPLACE
$body-bg:                   $white !default;
$header-height: 70px;
$footer-height: 70px;
$border: 2px solid $grey10;
$light-border: 1px solid $grey;
$bg-gray: $grey;
$offcanvas-horizontal-width: 50vw;
$navbar-nav-link-padding-x:         .7rem !default;
@mixin border($color: $hope) {
    border-block-end: 2px solid $color;
}

$accordion-button-active-bg:              $grey !default;
$accordion-button-active-color:              $hope !default;
$accordion-button-focus-box-shadow: none !default;
$accordion-padding-y:                     1.3rem !default;
$nav-pills-link-active-color: $hope;
$nav-pills-link-active-bg:          $hope !default;