.manage-account {
    h5 {
        text-align: center;
        font-size: 18px;
    }
    &__option {
        &__icon {
            display: grid;
            place-items: center;
            padding-top: 35px;
        }
        &__head {
            display: grid;
            place-items: center;
            color: $hope;
            font-size: 28px;
            font-weight: bold;
        }
        &__text {
            display: grid;
            place-items: center;
        }
        &__link {
            display: grid;
            place-items: center;
            color: $hope;
            &:hover {
                cursor: pointer;
            }
        }
    }
}