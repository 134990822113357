// This is styling for the resources page. For styling for myLearning is in _resources.scss
.myLearning {
    &__body {
        display: grid;
        grid-auto-rows: 1fr;
    }
    &__module {
        background-color: #ffffff;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        padding: 0 15px;
        min-height: 550px;
        overflow: hidden;
        &__header {
            border-bottom: 1px solid rgb(236, 236, 236);
        }
        &__header-text {
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 25px;
            font-weight: 500;
            p {
                margin-bottom: 0;
            }
        }
        &__icon {
            display: grid;
            place-items: center end;
            font-weight: 600;
            overflow: hidden;
            .icon-container {
                border-radius: 50%;
                display: grid;
                place-items: center;
                height: 50px;
                width: 50px;
                .icon {
                    max-width: 30px;
                    fill: #ffffff;
                    height: auto;
                }
            }
        }
        &__details {
            display: grid;
            align-content: start;
            ul {
                margin-bottom: 25px;
                li {
                    padding-top: 20px;
                }
            }
        }
        &__downloads {
            .download-item {
                background-color: #fff;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
                margin-bottom: 10px;
                border: 1px solid #eee;
                &__icon {
                    display: grid;
                    place-items: center;
                }
                &__text {
                    display: grid;
                    place-items: center start;
                    p {
                        margin-bottom: 0;
                        font-weight: bold;
                    }
                }
                &--active {
                    background-color: $hope;
                    color: white;
                    &:hover {
                        cursor: pointer;
                        background-color: lighten($hope, 5);
                    }
                }
                &--inactive {
                    background-color: rgb(205, 205, 205);
                    color: rgb(173, 173, 173);
                    cursor: not-allowed;
                    .row {
                        pointer-events: none;
                    }
                }
            }
        }
        &--people {
            .icon-container {
                background-color: $hope;
            }
            .download-item {
                &__icon {
                    color: $hope;
                }
                &__text {
                    p {
                        color: $hope;
                    }
                }
            }
        }
        &--money {
            .icon-container {
                background-color: $uplift;
            }
            .download-item {
                &__icon {
                    color: $uplift;
                }
                &__text {
                    p {
                        color: $uplift;
                    }
                }
            }
        }
        &--entrepreneurial {
            .icon-container {
                background-color: $brand;
            }
            .download-item {
                &__icon {
                    color: $brand;
                }
                &__text {
                    p {
                        color: $brand;
                    }
                }
            }
        }
        &--work {
            .icon-container {
                background-color: $uplift;
            }
            .download-item {
                &__icon {
                    color: $uplift;
                }
                &__text {
                    p {
                        color: $uplift;
                    }
                }
            }
        }
        &--project-management {
            .icon-container {
                background-color: $uplift;
            }
            .download-item {
                &__icon {
                    color: $uplift;
                }
                &__text {
                    p {
                        color: $uplift;
                    }
                }
            }
        }
    }
}
