$theme-colors: (
	"primary":    $hope,
	"secondary":  $grey50,
	"success":    $success,
	"info":       $grey30,
	"warning":    $warning,
	"danger":     $error,
	"light":      $white,
	"dark":       $black,
	"blue":       $energy,
);

$spacer: 1.25rem !default;

$font-size-base:              0.95rem !default;
$font-family-sans-serif: "SourceSansPro", sans-serif;
$focus-ring-width:      .25rem;
$focus-ring-opacity:    .25;
$focus-ring-color:      rgba($grey30, $focus-ring-opacity);
$focus-ring-blur:       0;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;
$form-text-margin-top:                  .1rem;

$border-radius:               .3rem !default;
$btn-padding-x:         2rem !default;
$btn-padding-y:         .475rem !default;
$input-padding-y:      .475rem !default;  
$input-color: $black;
$form-label-margin-bottom:              .1rem !default;
$form-label-font-size:                  14px !default;
$form-label-font-weight:                bold !default;
$form-label-color:                      $text !default;

$card-border-color:                 none !default;
$card-border-width:                 0 !default;
$card-border-radius: 6px !default;
$card-box-shadow: 0 1px 4px $black !important;